/** @jsxImportSource react */
import LoginForm from '$/components/LoginForm'
import type A11yDialogInstance from 'a11y-dialog'
import { PropsWithChildren, ReactNode, useEffect, useRef } from 'react'
import SiteModal from './SiteModal'
import { SITEWIDE_PROMO_COPY } from '@config'
import GoogleButton from '@components/GoogleButton/GoogleButton'
import Seperator from '@components/Seperator/Seperator'

export interface Props {
  buttonContent?: ReactNode
  buttonLabel?: string
  classNames?: {
    button?: string
  }
  onOpen?: () => void
}

export default function LoginModal(props: PropsWithChildren<Props>) {
  const dialog = useRef<A11yDialogInstance>()

  useEffect(() => {
    const handler = () => dialog.current?.show()
    window.addEventListener('ui:login', handler)
    return () => window.removeEventListener('ui:login', handler)
  }, [])

  return (
    <SiteModal
      id="login"
      dialogRef={(d) => (dialog.current = d)}
      closeButtonLabel="Close this dialog window"
      classNames={{
        container: 'site-dialog-container',
        overlay: 'site-dialog-overlay',
        dialog: 'site-dialog-content <tablet:!u-w-full tablet:u-w-[640px] tablet:u-rounded-lg desktop:u-w-[840px]',
        header:
          '<tablet:u-mt-4 <tablet:u-flex <tablet:u-items-center <tablet:u-flex-row-reverse <tablet:u-justify-end <tablet:u-border-b <tablet:u-pb-4 <tablet:u-pl-[15px] <tablet:u-pr-4 tablet:u-relative',
        closeButton: 'u-h-6 u-w-6 tablet:u-absolute tablet:u-right-6 tablet:u-top-6 <tablet:u-mr-2',
        title: 'p3 u-font-semibold tablet:u-hidden',
      }}
      title="Login"
    >
      <div className="u-flex u-flex-1 u-flex-col u-overflow-y-scroll tablet:u-flex-row tablet:u-overflow-auto">
        <img
          src="/_redesign/assets/pages/login/Login_Image.jpg"
          className="u-pointer-events-none u-h-[220px] u-w-full u-object-cover tablet:u-h-auto tablet:u-w-1/2 <tablet:u-hidden"
          height="466"
          width="466"
          alt=""
          loading="lazy"
          decoding="async"
        />
        <div className="visual full-width tablet:u-hidden">
          <img
            src="/_redesign/assets/pages/login/Login_Image.jpg"
            alt=""
            loading="lazy"
            decoding="async"
            className="u-w-full u-object-cover <tablet:u-max-h-[300px]"
          />
        </div>
        <div className="u-flex u-flex-1 u-flex-col u-px-[15px] u-pb-10 u-pt-6 tablet:u-w-1/2 tablet:u-p-10 desktop:u-min-h-[476px] desktop:u-justify-between">
          <div>
            <h2 className="h1 tablet:h2 u-mb-10 <lg:u-text-center tablet:u-mb-6">Welcome Back</h2>
            <LoginForm />
          </div>
          <aside className="u-mb-6 u-mt-14 u-text-center tablet:u-mt-10">
            <div className="tablet:p2 u-mb-2">Not a HUM member? {SITEWIDE_PROMO_COPY} off your first order.</div>
            <a href="/get_started" className="btn btn-block btn-uncontained">
              Get Started
            </a>
          </aside>
          <Seperator />
          <div className="u-mt-6">
            <GoogleButton className="!u-w-full" />
          </div>
        </div>
      </div>
    </SiteModal>
  )
}
